@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

body {
  @apply bg-white;
  @apply font-cartier-sans;
  @apply selection:bg-theme-blue-logo selection:text-white;
  @apply text-gray-700;
  @apply tracking-wide;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold;
  @apply leading-snug;
  @apply text-lg;
  /* @apply font-cartier-serif; */
}

a {
  @apply border-b-2;
  @apply border-transparent;
  @apply duration-300;
  @apply font-semibold;
  @apply hover:border-black;
  @apply hover:text-black;
  @apply text-theme-blue-logo;
  @apply transition-all;
}

a.border-effect {
  @apply after:-bottom-2;
  @apply after:absolute;
  @apply after:bg-theme-blue-logo;
  @apply after:duration-300;
  @apply after:h-[2px];
  @apply after:left-0;
  @apply after:transition-all;
  @apply after:w-full;
  @apply border-none;
  @apply hover:after:w-0;
  @apply relative;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  @apply w-4;
}
/* track */
::-webkit-scrollbar-track {
  @apply bg-gray-200;
}
/* handle */
::-webkit-scrollbar-thumb {
  @apply bg-black;
}
/* handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-theme-blue-logo;
}
